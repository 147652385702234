<template>
  <auth-layout>
    <div class="auth-contents">
      <a-card :bordered="false" layout="vertical">
        <sd-heading as="h3">{{$t('views.setPassword.title')}}</sd-heading>
        <div class="color-secondary mb-10">
            {{$t('views.setPassword.message')}}
        </div>
        <app-form
          ref="setPasswordForm"
          :fields="fields"
          :hideRequiredMark="true"
          :hasAlertList="true"
          :loading="loading"
          :submitButton="submitButton"
          @submitted="handleSubmit"
        >
          <template v-slot:alertList>
            <sd-alerts v-if="!$_.isEmpty(error)" message="" :description="$t(error)" :closable="false" type="error"/>
          </template>
        </app-form>
      </a-card>
    </div>
  </auth-layout>
</template>
<script>
import {
  computed, watch, ref, reactive
} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {AuthLayout} from './style';
import {required} from '@/helpers/validationRules';
import {useI18n} from 'vue-i18n';
import _ from 'lodash';

export default {
  name: 'Login',
  components: {
    AuthLayout
  },
  setup() {
    const {
      state, commit, dispatch
    } = useStore();
    const route = useRoute();
    const {t} = useI18n();

    const setPasswordForm = ref();
    const error = computed(() => state.layout.error);

    const currentRole = ref('');

    const setRoles = () => {
      currentRole.value = route.meta.key;
      commit('setUserType', route.meta.userType);
      commit('setError', '');
      setPasswordForm?.value?.cancelSubmit();
    };

    watch(() => route.path, () => {
      if (_.includes(['BackOfficeResetPassword', 'AgentResetPassword',
        'ClientResetPassword', 'AgentAcceptInvitation', 'ClientAcceptInvitation',
        'BackOfficeAcceptInvitation'], route.name)) {
        setRoles();
      }
    }, {immediate: true});

    const fields = {
      password: {
        type: 'password',
        class: 'mb-15',
        size: 'large',
        label: 'models.user.attributes.password',
        validateDependent: 'passwordConfirmation',
        fieldValue: '',
        span: 24,
        rules: [{
          ...required, message: t('messages.error.required')
        },
        {
          min: 8, message: t('messages.error.minLength', {min: 8}), trigger: ['blur', 'change'], type: 'string'
        }]
      },
      passwordConfirmation: {
        type: 'password',
        class: 'mb-15',
        customValidation: true,
        validateAgainst: 'password',
        label: 'models.user.attributes.passwordConfirmation',
        size: 'large',
        fieldValue: '',
        span: 24,
        rules: [{...required, message: t('messages.error.required')}]
      }
    };

    const submitButton = reactive({
      label: 'views.setPassword.title',
      class: 'btn-signin',
      size: 'large',
      type: 'primary',
      block: false
    });

    const loading = ref(false);
    const handleSubmit = (data) => {
      loading.value= true;
      if (route.name.toString().includes('AcceptInvitation')) {
        data['invitation_token'] = route.query['invitation_token'];
      } else {
        data['reset_password_token'] = route.query['reset_password_token'];
      }
      dispatch('resetPassword', data).then(() => loading.value = false);
    };

    return {
      setPasswordForm,
      currentRole,
      fields,
      submitButton,
      loading,
      error,
      handleSubmit
    };
  }
};
</script>
